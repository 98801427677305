;(function($) { // 即時関数で囲む（先頭に「;」があるのは他のプラグインと併用した場合に、最後に「;」を付けてないプラグインがまれにあるそうで、それが原因でのエラーを回避できます。）

  $.fn.cheapnav = function(options){
    // 変数の設定
    var opt = $.extend( {
      menuBtn        : '#js-burger',
      openElement    : 'body',
      openClass      : '.open-menu',
      overlayClass   : '.drawer-menu-overlay',
      parentClass    : '.trigger',
      menuList       : '.sm-nav .global-menu',
      childClass     : '.child',
      triggerId      : 'trigger',
      moveClass      : '.general-layout',
      wrapperClass   : '.cheapnav-wrapper',
      slideDownSpeed : 400
    }, options);

    // navのソースをコピーしてスマホ用のclassを付与する
    $(this).clone().addClass('sm-nav').appendTo('#sm-menu');
    // 親要素にidを付与する
    $('.sm-nav .trigger').attr('id', opt.triggerId);
    // メニューが開いたときに同時に動かすコンテンツエレメントにclassを付与
    $(opt.moveClass).addClass( opt.wrapperClass.slice(1) );

    // メニューボタンをクリックしたら発火
    $(opt.menuBtn).on( 'click', function(){
      $(opt.openElement).toggleClass( opt.openClass.slice(1) );
      $(opt.menuList).slideToggle( opt.slideDownSpeed );
    });
    // オーバーレイをクリックしたらメニューが消える
    $(opt.overlayClass).on('click', function() {
      $(opt.openElement).removeClass( opt.openClass.slice(1) );
    });
    // 子メニューのアクション
    $(document).on('click', '#' + opt.triggerId, function() {
      $(this).next('ul'+opt.childClass).slideToggle( opt.slideDownSpeed );
    });

  };

})(jQuery);
